import { graphql } from "gatsby"
import { array } from "prop-types"
import React, { useState } from "react"
import { FaDownload, FaSortAmountDown } from "react-icons/fa"
import {
  addDateToSortByField,
  sortPostByDate,
  sortByTitle,
  sortByX,
} from "../../helperFunctions"
import DropdownMenu from "../navbar/dropDownMenu"

const TitleText = ({ children, className, dark = false, sort = null }) => (
  <div
    className={`italic font-normal uppercase  hidden lg:block tracking-wide ${className}`}
  >
    <span className="flex items-center">
      {children}{" "}
      {sort && (
        <button
          className="cursor-pointer"
          title={`Sort by ${children.toLowerCase()}`}
          onClick={() => {
            sort()
          }}
        >
          <FaSortAmountDown className="ml-1 text-sm text-fifth" />
        </button>
      )}
    </span>
  </div>
)

const RowDivider = ({ dark = false }) => (
  <div className={` border-b border-primary my-3 lg:col-span-5`}></div>
)

const ItemName = ({
  title = "ERROR: Title missing",
  dark = false,
  className = "",
  url,
}) => (
  <a
    href={`${url}?dl=`}
    className={`text-primary hover:text-fifth text-lg lg:text-xl break-words text-center lg:text-left font-bold ${className}`}
  >
    {title}
  </a>
)

const ItemLink = ({ url = "/#", green = true }) =>
  green ? (
    <a
      href={`${url}?dl=`}
      className="bg-fifth text-white hover:bg-white hover:text-fifth border-2 border-fifth rounded uppercase hover:underline text-center download flex justify-center items-center w-20 justify-self-end py-1 px-4"
      title="Download file"
    >
      <FaDownload />
    </a>
  ) : (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-white uppercase hover:underline"
    >
      Visit
    </a>
  )

const FileDate = ({ dateString = "ERROR: Date missing" }) => (
  <div className="text-center inline-block">{dateString}</div>
)

const tags = {
  hfoForms: { title: "HFO", color: "hfo" },
  physicianForms: { title: "Physicians", color: "physician" },
  studentForms: { title: "Students", color: "student" },
}

export const DownloadsTableContainer = ({ children }) => {
  return (
    <div className=" grid justify-items-stretch w-full ">
      <div className="max-w-7xl w-11/12 justify-self-center">{children}</div>
    </div>
  )
}

export const DownloadsTable = ({ downloads = [] }) => {
  const [sortBy, setSortBy] = useState("dateAdded")
  const [filterBy, setFilterBy] = useState("")
  let downloadsArray = []
  let arraySortedByDates = []

  const handleChange = (value) => {
    setSortBy(value)
  }

  // Add date object (.dateToSortBy) and stringified date (.dateString) fields
  let arrayWithNewDateField = addDateToSortByField(downloads)

  // Sort logic, based on stateful value of sortBy
  if (sortBy === "dateAdded") {
    sortPostByDate(arrayWithNewDateField)
  } else if (sortBy === "title") {
    // Make sure array is first sorted by date
    arraySortedByDates = sortPostByDate(arrayWithNewDateField)
    sortByTitle(arraySortedByDates)
  } else if (sortBy === "category") {
    // Make sure array is first sorted by date
    arraySortedByDates = sortPostByDate(arrayWithNewDateField)
    sortByX(arraySortedByDates, "category")
  }

  // Filter logic
  if (filterBy !== "") {
    downloadsArray = arrayWithNewDateField.filter(
      (item) => item.category === filterBy
    )
  } else {
    downloadsArray = arrayWithNewDateField
  }

  return (
    <>
      <div className="grid">
        {/* <button className="border p-2 rounded" onClick={() => sortFiles()}>
            Sort
          </button> */}

        {/* Filter table */}
        <form className=" justify-self-end">
          <label className="font-bold" htmlFor="filtering">
            Filter By:&nbsp;
          </label>
          <select
            name="options"
            id="options"
            className="border border-primary px-2 cursor-pointer"
            onChange={(e) => {
              return setFilterBy(e.target.value)
            }}
          >
            <option value={""}>Show all</option>
            <option value="hfoForms">HFO Forms</option>
            <option value="studentForms">Student Forms</option>
            <option value="physicianForms">Physician Forms</option>
          </select>
        </form>
        {/* Sort options only visible on mobile */}
        <form className=" justify-self-end lg:hidden mt-2">
          <label className="font-bold" htmlFor="sorting">
            Sort By:&nbsp;
          </label>
          <select
            name="options"
            id="options"
            className="border border-primary px-2 cursor-pointer"
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="dateAdded">Date Added</option>
            <option value="title">File Name</option>
            <option value="category">Tag</option>
          </select>
        </form>
        <div className=" grid items-start mt-5  lg:grid-cols-downloadsSm gap-x-5 ">
          <TitleText sort={() => setSortBy("title")}>File Name</TitleText>
          <TitleText className=" hidden lg:block">Description</TitleText>
          <TitleText
            sort={() => setSortBy("dateAdded")}
            className=" text-center px-10 hidden lg:block"
          >
            Date
          </TitleText>
          <TitleText className="px-5 text-center hidden lg:block">
            Download
          </TitleText>
          <TitleText
            sort={() => setSortBy("category")}
            className="px-5 text-center hidden lg:block"
          >
            Tag
          </TitleText>

          <RowDivider />

          {downloadsArray.length ? (
            downloadsArray.map((download) => {
              return (
                <>
                  <ItemName
                    title={download.title}
                    url={download.file.asset.url}
                  />
                  {/* Description */}
                  <div className="text-center lg:text-left mx-2 my-1 lg:m-0">
                    {download.description}
                  </div>

                  <div className="flex justify-center font-normal italic">
                    <span className="lg:hidden italic ">Date:&nbsp;</span>
                    <FileDate dateString={download.dateString} />
                  </div>
                  <div className="justify-self-end lg:justify-self-center mt-3 lg:mt-0">
                    <div className="flex">
                      <span className="mr-2 lg:hidden italic text-gray-500">
                        Download:
                      </span>
                      <ItemLink url={download.file.asset.url} />
                    </div>
                  </div>
                  <div className="flex justify-self-end items-center mt-2 lg:mt-0 ">
                    {/* <span className=" mr-2 lg:hidden italic text-gray-500">
                      Tag:
                    </span> */}
                    <div
                      className={`justify-self-end  rounded px-1  font-bold italic ${
                        tags[download.category].color === "hfo"
                          ? " text-secondary"
                          : tags[download.category].color === "physician"
                          ? " text-quaternary"
                          : tags[download.category].color === "student"
                          ? " text-fifth"
                          : "text-black"
                      }`}
                    >
                      {tags[download.category].title}
                    </div>
                  </div>

                  <RowDivider />
                </>
              )
            })
          ) : (
            <div className="text-center">No files currently</div>
          )}
        </div>
      </div>
    </>
  )
}

export const data = graphql`
  fragment DownloadsFragment on SanityDownloads {
    title
    description
    category
    _rawFile(resolveReferences: { maxDepth: 10 })
    _createdAt
    file {
      asset {
        url
      }
    }
  }
`
