import React from "react"
import Img from "gatsby-image"

const Media = ({ props }) => {
  const isImage = typeof props[0] === "object"

  const className = {
    container:
      "grid grid-flow-col w-full mt-three h-twen" +
      " " +
      (isImage ? "mb-three" : ""),
    media:
      "rounded mr-three" +
      " " +
      (isImage ? "h-fifteen 2xl:h-twen w-q 2xl:w-thirty" : "h-twen w-thirty"),
  }

  return (
    <div className={className.container}>
      {props.map((item, index) => {
        return (
          <>
            {isImage ? (
              <Img fluid={item} key={index} className={className.media} />
            ) : (
              <div className={className.media} key={index}>
                <video controls>
                  <source src={item} type="video/mp4" />
                </video>
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

const Card = ({ props }) => {
  const className =
    "w-eighty h-thirty justify-items-center items-start grid grid-cols-2 relative top-tw right-forty font-bold " +
    props.container?.className

  return (
    <div className={className}>
      {props.header.hide !== true && (
        <div
          className={`text-xl md:text-5xl h-full right-q md:right-thirty -top-5 md:top-0 absolute transform md:-rotate-90`}
        >
          {props.header.text}
        </div>
      )}

      <div className="absolute top-three inset-x-thirtyfive h-full">
        <div
          className={`${props.divider.className} ${
            props.header.hide ? "opacity-0" : ""
          } absolute -top-1 md:top-o2 left-tw md:left-0 w-ten h-0.5 md:w-0.5 md:h-ten`}
        />

        <div className="absolute inset-x-tw grid-flow-row w-full h-full">
          {props.text?.map((text) => {
            return (
              <p className="font-semibold mb-o w-seventy md:w-ninety text-xs md:text-base">
                {text}
              </p>
            )
          })}

          {(props.images?.length > 0 || props.video?.length > 0) && (
            <Media
              props={props.images?.length > 0 ? props.images : props.video}
            />
          )}

          {props.button.text !== null && (
            <button
              type="submit"
              className={`${props.button.className} w-twen font-bold`}
            >
              {props.button.text}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
