import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// import urlBuilder from "@sanity/image-url"
// import PortableText from "@sanity/block-content-to-react"
import { FaPhone, FaStoreAlt } from "react-icons/fa"

import ContactForm from "../components/contactForm"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Banner from "../components/banner"
import DownloadContainer from "../components/downloadContainer"
import HeroContainer from "../components/heroContainer"
import IndexContainer from "../components/indexContainer"
import {
  DownloadsTable,
  DownloadsTableContainer,
} from "../components/downloads/DownloadsTable"
import PageTitle from "../components/PageTitle"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"

const Downloads = ({ data }) => {
  const { sanityDownloadsPage } = data
  // custom header from Sanity. If none provided, this will be null
  const defaultHeroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBanner.childImageSharp.fluid

  const fluidBanner =
    getFluidGatsbyImage(
      sanityDownloadsPage?._rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || defaultHeroFluid

  debugger

  const downloads = data.downloads.edges.map((item) => item.node)

  const heroProps = {
    fluid: fluidBanner,
    gradient: true,
  }

  return (
    //pass custom header to Layout
    <Layout>
      <SEO title={sanityDownloadsPage.title} />

      <HeroContainer props={heroProps} />
      <PageTitle text={sanityDownloadsPage.title} />
      <IndexContainer
        props={{
          fluid: null,
          className: " my-20 relative text-primary",
        }}
      >
        <DownloadsTableContainer>
          <DownloadsTable downloads={downloads} />
        </DownloadsTableContainer>
      </IndexContainer>
    </Layout>
  )
}

export default Downloads

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "home_hero.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBanner: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    downloads: allSanityDownloads(sort: { fields: _createdAt }) {
      edges {
        node {
          ...DownloadsFragment
        }
      }
    }
    sanityDownloadsPage {
      title
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
    }
  }
`
