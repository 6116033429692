import React from "react"

// Flattens edges array from graphql queries into a simple array without "edges.node"
// export const flattenEdgesArray = (array) =>
//   array.edges.flatMap((arrayItem) => arrayItem.node)

const toDateString = (dateObject) => {
  const year = dateObject.getFullYear()

  return `${
    monthArray[dateObject.getMonth()]
  } ${dateObject.getDate()}, '${year.toString().slice(2)}`
}

export function flattenEdgesArray(data) {
  const edges = data.edges
  if (edges.length > 0) {
    return edges.flatMap((edge) => [edge.node])
  }
  return null
}

export const addDateToSortByField = (posts) => {
  if (posts) {
    return posts.map((post) => {
      const createdAtDate = new Date(post._createdAt)

      let editableDate = post.publishedDate
        ? new Date(post.publishedDate)
        : null

      // if (post.publishedDate) {
      //   editableDate = new Date(post.publishedDate)
      // } else {
      //   editableDate = null
      // }

      post.dateToSortBy = editableDate || createdAtDate

      post.dateString = `${post.dateToSortBy.getDate()}-${
        monthArray[post.dateToSortBy.getMonth()]
      }-${post.dateToSortBy.getFullYear()}`

      return post
    })
  } else {
    return null
  }
}

export const addExtraDateInfo = (posts) => {
  if (posts) {
    return posts.map(({ node: post }) => {
      const dateObj = new Date(post.eventDateTime)
      const mins = `0${dateObj.getMinutes()}`

      const time = `${dateObj.getHours()}:${mins.slice(-2)}`
      post.time = time
      const dateString = toDateString(dateObj)
      post.dateString = dateString
      return post
    })
  } else {
    return null
  }
}

// desc by default. Any other value will result in ascending order
export const sortPostByDate = (posts = [], order = "desc") => {
  if (order === "desc") {
    return posts.sort((a, b) => b.dateToSortBy - a.dateToSortBy)
  } else {
    return posts.sort((a, b) => a.dateToSortBy - b.dateToSortBy)
  }
}

export const sortByTitle = (array = []) => {
  array.sort((a, b) => {
    let titleA = a.title.toUpperCase()
    let titleB = b.title.toUpperCase()
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    return 0
  })
}

export const sortByX = (array = [], sortBy = "") => {
  array.sort((a, b) => {
    let fieldA = a[sortBy]
    let fieldB = b[sortBy]

    if (fieldA < fieldB) {
      return -1
    }
    if (fieldA > fieldB) {
      return 1
    }
    return 0
  })
}

export const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const monthArrayFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

// Portable text to plain text (for previews)

export function toPlainText(blocks = []) {
  const plainTextArray = blocks
    // loop through each block
    .map(
      (block, index) => {
        // if it's not a text block with children,
        // return nothing
        if (
          block._type === "block" &&
          block.children &&
          block.style === "normal"
        ) {
          // loop through the children spans, and join the
          // text strings
          return block.children.map((child) => child.text).join("")
        }
      }

      // join the paragraphs leaving split by two linebreaks
    )

  return plainTextArray
}

// export const blockTextToPlainText = (blocks = []) => {
//   const plainTextArray = blocks.map((block, index) => {
//     if (block._type == "block" && block.children && block.style == "normal") {
//       // loop through the children spans, and join the
//       // text strings
//       return block.children.map((child) => child.text).join("")
//     }
//   })

//   const plainTextElement

//   const plainTextElement = plainTextArray.forEach((item) => {
//     if (item) {
//     }
//   })

//   return plainTextArray
// }

export const BlockTextToPlainText = ({ blocks = [] }) => {
  const filteredBlocks = blocks.filter(
    (block) =>
      block._type === "block" && block.children && block.style === "normal"
  )

  return (
    <div>
      {/* This works but decided to only return first paragraph */}
      {/* {filteredBlocks.map((block) => (
        <p>{block.children.map((child) => child.text).join("")}</p>
      ))} */}
      <p>{filteredBlocks[0].children.map((child) => child.text).join("")}</p>
    </div>
  )
}
