import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import BoxesContainer from "../components/boxesContainer"
import Card from "../components/card"
import { graphql, useStaticQuery } from "gatsby"
import Gradient from "./gradient"

const CardLogic = ({ props }) => {
  const className =
    "mt-2 gap-20 2xl:mt-0 2xl:gap-0 mt-2 grid grid-flow-row place-items-center"
  return (
    <div className={className}>
      {props?.cardProps?.map((card) => {
        return <Card props={card} />
      }) && null}
    </div>
  )
}

const IndexContainer = ({
  props,
  children,
  noBkgd = false,
  className = "",
}) => {
  const data = useStaticQuery(graphql`
    query {
      defaultBackground: file(relativePath: { eq: "second_banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const containerClass = `grid grid-cols-1 justify-items-center items-start h-screen w-screen relative ${props?.className}`

  const backgroundImage =
    props?.fluid || data.defaultBackground.childImageSharp.fluid

  const backgroundFluidImageStack = [
    backgroundImage,
    `linear-gradient(0deg, rgba(255,255,255,0.65) 12%, rgba(255,255,255,0.65) 30%)`,
  ].reverse()

  // Render background image only is props is provided (even if props.fluid is null)
  const renderBackgroundImage = props?.fluid

  return (
    <>
      {!noBkgd ? (
        <BackgroundImage
          className={`grid grid-cols-1 justify-items-center  items-start min-h-xl relative  ${props?.className} ${className} `}
          fluid={backgroundFluidImageStack}
        >
          <Gradient className="h-40 absolute inset-x-0 top-0  from-white z-10  " />
          {/* z-index ensures that children sit above the gradient */}
          <div className="z-20 max-w-7xl w-full grid ">{children}</div>
        </BackgroundImage>
      ) : (
        <div className={`${containerClass}`}>
          <CardLogic props={props} />
          {children}
        </div>
      )}
    </>
  )
}

export default IndexContainer
