import React from "react"
import BackgroundImage from "gatsby-background-image"
import months from "../data/data"

import Gradient from "./gradient"
import { checkPropTypes } from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

const HeroContainer = ({ props, className = "" }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      defaultHeroImage: file(relativePath: { eq: "opportunity_hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const gradientClass = " h-4/5 absolute inset-x-0 bottom-0 from-white "

  const heroImage = props?.fluid || data.defaultHeroImage.childImageSharp.fluid

  return (
    <BackgroundImage
      className={` min-h-xxs md:min-h-xs relative font-bold uppercase border-white place-items-center grid grid-cols-1   ${className}`}
      fluid={heroImage}
    >
      {props?.text && (
        <div
          className={` py-10 px-12 gap-y-3 md:gap-y-5 p-thirty grid grid-cols-1  bg-white bg-opacity-90 rounded text-center mx-10 max-w-6xl`}
        >
          {props?.text.map((text, index) => {
            const className =
              "text-4xl lg:text-4xl 2xl:text-6xl font-semi-bold tracking-wide " +
              (index % 2 === 0 ? "text-black" : "text-quaternary")
            return (
              <h1 key={`hero-${index}`} className={` ${className}`}>
                {text}
              </h1>
            )
          })}
        </div>
      )}

      {props?.gradient && <Gradient className={` ${gradientClass}`} bottom />}
    </BackgroundImage>
  )
}

export default HeroContainer

// {props.date && <div className="grid grid-cols-1 place-items-center w-1/2 h-1/4"><p className="">{dateText.month}</p><p className="">{dateText.day}</p></div>}
