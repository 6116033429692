import React from "react"

const Gradient = ({ className, bottom = false }) => {
  if (bottom) {
    return <div className={` bg-gradient-to-t w-full ${className}`} />
  } else {
    return <div className={`bg-gradient-to-b w-full ${className}`}></div>
  }
}

export default Gradient
