import React from "react"

const PageTitle = ({ text = "Title not provided", className = "" }) => {
  return (
    <h1
      className={` text-secondary text-center uppercase tracking-wide mt-10 leading-none ${className}`}
    >
      {text}
    </h1>
  )
}

export default PageTitle
