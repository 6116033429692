import React from 'react'

const Banner = ({ className, children }) => {

  return (
    <div className={`${className}`}>
      {children}
    </div>
  )
}

export default Banner 
